import ContactIcon from '@mui/icons-material/ChatBubble';
import ContactList from "./ContactList"
import ContactCreate from "./ContactCreate"
import ContactEdit from "./ContactEdit"

const resource = {
  list: ContactList,
  create: ContactCreate,
  edit: ContactEdit,
  icon: ContactIcon,
}
  
export default resource
