import * as React from "react"
import {
  BooleanField,
  CreateButton,
  DatagridConfigurable,
  DateField,
  DateInput,
  ExportButton,
  List,
  NullableBooleanInput,
  NumberField,
  SearchInput,
  SelectColumnsButton,
  TopToolbar,
  TextField,
  EmailField,
  useListContext,
  DeleteButton,
  useRecordContext,
  EditButton
} from "react-admin"
import { useMediaQuery, Button, Box } from "@mui/material"
import ContentFilter from '@mui/icons-material/FilterList';

import CategoryFilterForm from "./CategoryFilterForm"

const CategoryFilterButton = () => {
  const { showFilter } = useListContext()
  return (
    <Button
      size="small"
      color="primary"
      onClick={() => showFilter("main")}
      startIcon={<ContentFilter />}
      sx={{
        height: '27.5px', // 調整按鈕高度
        padding: '4px 5px', // 調整內邊距
        fontSize: '13px', // 調整字型大小，這樣可以與 CreateButton 大小對齊
      }}
    >
      篩選
    </Button>
  )
}

const visitorFilters = [
  <SearchInput source="q" alwaysOn />,
  // <DateInput source="last_seen_gte" />,
  // <NullableBooleanInput source="has_ordered" />,
  // <NullableBooleanInput source="has_newsletter" defaultValue />,
  // <SegmentInput source="groups" />
]

const CategoryListActions = () => (
  <Box width="100%">
    <TopToolbar>
      <CategoryFilterButton />
      <CreateButton />
      {/* <SelectColumnsButton /> */}
      {/* <ExportButton /> */}
    </TopToolbar>
    <CategoryFilterForm />
  </Box>
)

const CategoryTitle = () => {
  return <span>{'文章類別'}</span>;
};

const CategoryList = () => {
  const isXsmall = useMediaQuery(theme => theme.breakpoints.down("sm"))
  const isSmall = useMediaQuery(theme => theme.breakpoints.down("md"))
  return (
    <List
      title={<CategoryTitle/>}
      filters={isSmall ? visitorFilters : undefined}
      sort={{ field: "createdAt", order: "DESC" }}
      perPage={25}
      actions={<CategoryListActions />}
    >
      {isXsmall ? (
        // <MobileGrid />
        <div></div>
      ) : (
        <DatagridConfigurable
          rowClick="edit"
          sx={{
            "& .column-groups": {
              md: { display: "none" },
              lg: { display: "table-cell" }
            }
          }}
          omit={["id"]}
        >
          <TextField
            source="id"
            label="ID"
          />
          <TextField
            source="name"
            label="名稱"
          />
          <DateField 
            source="createdAt" 
            label="建立日期"  
            showTime
          />
          <DeleteButton />
        </DatagridConfigurable>
      )}
    </List>
  )
}

export default CategoryList