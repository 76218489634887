import * as React from "react"
import {
  DateInput,
  Edit,
  NullableBooleanInput,
  TextInput,
  PasswordInput,
  SimpleForm,
  useTranslate,
  BooleanInput
} from "react-admin"
import { Grid, Box, Typography } from "@mui/material"

import { validateForm } from "./MemberCreate"

const MemberEdit = () => {
  const translate = useTranslate()
  return (
    <Edit>
      <SimpleForm 
        defaultValues={{
          status: true, // Default status to active
          firstName: "",
          dob: "",
          email: "",
          phone: "",
          mobile: "",
          account: "",
          password: "",
          confirm_password: "" // Will be automatically filled with password when entered
        }}
        validate={validateForm}>
        <div>
          <Grid container width={{ xs: "100%", xl: 800 }} spacing={2}>
            <Grid item xs={12} md={8}>
              <Typography variant="h6" gutterBottom>
                {/* {translate("resources.customers.fieldGroups.identity")} */}
                {'基本資料'}
              </Typography>
              <TextInput source="firstName" label="名稱" isRequired />
              <DateInput source="dob" label="生日" />
              <TextInput type="email" source="email" label="Email" isRequired />
              <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <TextInput source="phone" label="電話" />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                <TextInput source="mobile" label="手機" />
                </Box>
              </Box>
              <TextInput source="account" label="帳號" isRequired />
              <BooleanInput label="狀態" source="status" />
              <Box mt="1em" />
              <Typography variant="h6" gutterBottom>
                {translate("resources.customers.fieldGroups.change_password")}
              </Typography>
              <Box display={{ xs: "block", sm: "flex" }}>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <PasswordInput source="password" />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <PasswordInput source="confirm_password" />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </div>
      </SimpleForm>
    </Edit>
  )
}

export default MemberEdit