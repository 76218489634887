import * as React from "react"
import {
  BooleanField,
  CreateButton,
  DatagridConfigurable,
  DateField,
  DateInput,
  ExportButton,
  List,
  NullableBooleanInput,
  NumberField,
  SearchInput,
  SelectColumnsButton,
  TopToolbar,
  TextField,
  EmailField,
  useListContext,
  DeleteButton
} from "react-admin"
import { useMediaQuery, Button, Box } from "@mui/material"
import ContentFilter from '@mui/icons-material/FilterList';

// import SegmentsField from "./SegmentsField"
// import SegmentInput from "./SegmentInput"
// import UserLinkField from "./UserLinkField"
// import ColoredNumberField from "./ColoredNumberField"
// import MobileGrid from "./MobileGrid"
import ProductListAside from "./ProductListAside"

const visitorFilters = [
  <SearchInput source="q" alwaysOn />,
  // <DateInput source="last_seen_gte" />,
  // <NullableBooleanInput source="has_ordered" />,
  // <NullableBooleanInput source="has_newsletter" defaultValue />,
  // <SegmentInput source="groups" />
]

const ProductListActions = () => (
  <TopToolbar>
    <CreateButton />
    {/* <SelectColumnsButton /> */}
    {/* <ExportButton /> */}
  </TopToolbar>
)

const ProductList = () => {
  const isXsmall = useMediaQuery(theme => theme.breakpoints.down("sm"))
  const isSmall = useMediaQuery(theme => theme.breakpoints.down("md"))
  return (
    <List
      filters={isSmall ? visitorFilters : undefined}
      sort={{ field: "name", order: "ASC" }}
      perPage={25}
      aside={<ProductListAside />}
      actions={<ProductListActions />}
    >
      {isXsmall ? (
        // <MobileGrid />
        <div></div>
      ) : (
        <DatagridConfigurable
          rowClick="edit"
          sx={{
            "& .column-groups": {
              md: { display: "none" },
              lg: { display: "table-cell" }
            }
          }}
          omit={["id"]}
        >
          <TextField
            source="id"
            label="ID"
          />
          <TextField
            source="name"
            label="名稱"
          />
          <DateField 
            source="createdAt" 
            label="建立日期"  
            showTime
          />
          <DeleteButton />
        </DatagridConfigurable>
      )}
    </List>
  )
}

export default ProductList