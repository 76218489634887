import polyglotI18nProvider from 'ra-i18n-polyglot';
import { 
  Admin,
  Resource,
  localStorageStore,
  StoreContextProvider,
  defaultTheme
} from "react-admin";
import HealthCheck from "./HealthCheck";
import customDataProvider from "./customDataProvider";
import authProvider from './authProvider';
import users from './users';
import members from "./members";
import categories from "./categories";
import products from "./products";
import contacts from "./contacts";
import { Layout, Login } from "./layout"
import en from './i18n/en';
import zhTW from './i18n/zh-TW';

const i18nProvider = polyglotI18nProvider(locale => 
  locale === 'zh-tw' ? zhTW : en,
  'zh-tw', // Default locale
  [
      { locale: 'en', name: 'English' },
      { locale: 'zh-tw', name: '繁體中文' }
  ],
);

const store = localStorageStore(undefined, "ECommerce")

const App = () => {
  // 若路徑為 /health，直接渲染 HealthCheck 組件
  if (window.location.pathname === "/health") {
    return <HealthCheck />
  }

  return (
    <Admin
      title=""
      store={store}
      dataProvider={customDataProvider}
      authProvider={authProvider}
      loginPage={Login}
      i18nProvider={i18nProvider}
      layout={Layout}
      defaultTheme={defaultTheme}
    >
      <Resource name="users" {...users} />
      <Resource name="members" {...members} />
      <Resource name="categories" {...categories} />
      <Resource name="products" {...products} />
      <Resource name="contacts" {...contacts} />
    </Admin>
  )
}

const AppWrapper = () => (
  <StoreContextProvider value={store}>
    <App />
  </StoreContextProvider>
)

export { AppWrapper as App };