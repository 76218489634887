import { fetchUtils } from 'react-admin';
import simpleRestProvider from 'ra-data-simple-rest';

const apiUrl = process.env.REACT_APP_API_URL;
const httpClient = (url, options = {}) => {
  options.headers = new Headers(options.headers || {});
  options.headers.set('x-api-key', process.env.REACT_APP_API_KEY);

  return fetchUtils.fetchJson(url, options);
};

const dataProvider = simpleRestProvider(apiUrl, httpClient);

const customDataProvider = {
    ...dataProvider,
    getList: (resource, params) => {
      const { filter, pagination, sort } = params;
      const { page, perPage } = pagination;
      const { field, order } = sort;

      // 轉換查詢參數為後端 API 所需格式
      const query = {
        ...Object.keys(filter).reduce((acc, key) => {
          // 只加入非空且非 undefined 的過濾條件
          console.log(key);
          if (filter[key] && filter[key] !== undefined) {
            console.log(key);
            acc[key] = filter[key];
          }
          return acc;
        }, {}),
        offset: (page - 1) * perPage, // 偏移量
        limit: perPage, // 每頁顯示筆數
        sort: field,
        order: order,
      };

      const url = `${apiUrl}/${resource}?${fetchUtils.queryParameters(query)}`;

      return httpClient(url).then(({ headers, json }) => {
        const { rows, count } = json.data;

        let data = [];
        // 處理回應的資料格式
        switch (resource) {
          case 'members':
            data = rows.map(row => ({
              ...row,
              account: row.user.account,
              email: row.user.email,
              status: row.user.status,
            }));
            break;
          default:
            data = rows.map(row => ({ ...row }));
        }
    
        return {
          data: data,
          total: count, // 總數來自 API 回應的 count 值
        };
      });
    },
    getOne: (resource, params) => {
      return dataProvider.getOne(resource, params).then(response => {
          // 將原始的 API 回應格式轉換
          const originalData = response.data.data;
          
          let data = {};
          // 處理回應的資料格式
          switch (resource) {
            case 'members':
              // 將 user 物件的屬性搬到原始物件，並移除 user key
              const { user, ...rest } = originalData;
              data = { ...rest, ...user };
              break;
            default:
              data = originalData;
          }

          return {
            data: data
          };
      });
    },
    create: (resource, params) => {
      return dataProvider.create(resource, params).then(response => {
        // 將原始的 API 回應格式轉換
        const data = response.data.data;
        return {
          data: data
        };
      });
    },
    update: (resource, params) => {
      return dataProvider.update(resource, params).then(response => {
        // 將原始的 API 回應格式轉換
        const data = response.data.data;
        return {
          data: data
        };
      });
    },
    // 其他 CRUD 操作的自訂邏輯...
};

export default customDataProvider;
