import ProductIcon from '@mui/icons-material/Inventory';
import ProductList from './ProductList';
import ProductCreate from './ProductCreate';

const resource = {
  list: ProductList,
  create: ProductCreate,
  icon: ProductIcon,
}
  
export default resource
