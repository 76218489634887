import * as React from "react"
import { Create, TabbedForm, TextInput, required, ImageInput, ImageField } from "react-admin"
import { ProductEditDetails } from "./ProductEditDetails"
const RichTextInput = React.lazy(() =>
  import("ra-input-rich-text").then(module => ({
    default: module.RichTextInput
  }))
)

const ProductCreate = () => (
  <Create>
    <TabbedForm defaultValues={{ sales: 0 }}>
      {/* <TabbedForm.Tab
        label="resources.products.tabs.image"
        sx={{ maxWidth: "40em" }}
      >
        <TextInput autoFocus source="image" validate={required()} />
        <TextInput source="thumbnail" validate={required()} />
      </TabbedForm.Tab> */}
      <TabbedForm.Tab
        label="resources.products.tabs.image"
        sx={{ maxWidth: "40em" }}
      >
        <ImageInput source="pictures" label="Related pictures" multiple >
          <ImageField source="src" title="title" />
        </ImageInput>
      </TabbedForm.Tab>
      <TabbedForm.Tab
        // label="resources.products.tabs.details"
        label="基本資料"
        path="details"
        sx={{ maxWidth: "40em" }}
      >
        <ProductEditDetails />
      </TabbedForm.Tab>
      <TabbedForm.Tab
        label="resources.products.tabs.description"
        path="description"
      >
        <RichTextInput source="description" label="" />
      </TabbedForm.Tab>
    </TabbedForm>
  </Create>
)

export default ProductCreate