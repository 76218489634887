import * as React from "react"
import {
  Create,
  SimpleForm,
  TextInput,
  useTranslate,
  PasswordInput,
  email,
  BooleanInput,
  DateInput
} from "react-admin"
import { Box, Typography } from "@mui/material"

export const validateForm = values => {
  const errors = {}
  if (!values.firstName) {
    errors.firstName = "ra.validation.required"
  }
  if (!values.account) {
    errors.account = "ra.validation.required"
  }
  if (!values.email) {
    errors.email = "ra.validation.required"
  } else {
    const error = email()(values.email)
    if (error) {
      errors.email = error
    }
  }
  if (values.password && values.password !== values.confirm_password) {
    errors.confirm_password = "resources.customers.errors.password_mismatch"
  }
  return errors
}

const MemberCreate = () => (
  <Create>
    <SimpleForm
      sx={{ maxWidth: 500 }}
      // Here for the GQL provider
      defaultValues={{
        status: true, // Default status to active
        firstName: "",
        dob: "",
        email: "",
        phone: "",
        mobile: "",
        account: "",
        password: "",
        confirm_password: "" // Will be automatically filled with password when entered
      }}
      validate={validateForm}
    >
      {/* <SectionTitle label="resources.customers.fieldGroups.identity" /> */}
      <SectionTitle label="基本資料" />
      <TextInput source="firstName" label="名稱" isRequired />
      <DateInput source="dob" label="生日" />
      <TextInput type="email" source="email" label="Email" isRequired />
      <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <TextInput source="phone" label="電話" />
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
          <TextInput source="mobile" label="手機" />
        </Box>
      </Box>
      <TextInput source="account" label="帳號" isRequired />
      <BooleanInput label="狀態" source="status" />
      {/* <SectionTitle label="resources.customers.fieldGroups.password" /> */}
      <Box display={{ xs: "block", sm: "flex" }}>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <PasswordInput source="password" label="密碼" isRequired />
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
          <PasswordInput source="confirm_password" label="再次輸入密碼" isRequired />
        </Box>
      </Box>
    </SimpleForm>
  </Create>
)

const SectionTitle = ({ label }) => {
  const translate = useTranslate()

  return (
    <Typography variant="h6" gutterBottom>
      {/* {translate(label)} */}
      {label}
    </Typography>
  )
}

const Separator = () => <Box pt="1em" />

export default MemberCreate