import * as React from "react"
import {
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  TextInput
} from "react-admin"
import { InputAdornment, Grid } from "@mui/material"

export const ProductEditDetails = () => (
  <Grid container columnSpacing={2}>
    <Grid item xs={12} sm={6}>
      <TextInput source="name" label="名稱" validate={req} />
    </Grid>
    <Grid item xs={12} sm={6}>
      <TextInput source="sku" label="SKU" />
    </Grid>
    <Grid item xs={12} sm={4}>
      <ReferenceInput source="category_id" reference="categories">
        <SelectInput optionText="name" validate={req} />
      </ReferenceInput>
    </Grid>
    <Grid item xs={12} sm={4}>
      <NumberInput
        source="price"
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>
        }}
        validate={req}
      />
    </Grid>
    <Grid item xs={12} sm={4}>
      <NumberInput source="stock" validate={req} />
    </Grid>
  </Grid>
)

const req = [required()]